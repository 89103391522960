<template lang="pug">
.page
  el-card.tableCard(v-loading="loading")
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(label="职位")
        template(#default="scope")
          el-tag(v-if="scope.row?.job") {{ scope.row?.job?.name }}
      el-table-column(label="报名手机号")
        template(#default="scope")
          el-tag(v-if="scope.row?.user") {{ scope.row?.user?.phone }}
      el-table-column(prop="desc" label="备注")
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="initData"
        @current-change="getList")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const { fetch, router, message } = inject('global')

const route = useRoute()
const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})

function initData() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function getList() {
  loading.value = true
  let params = {
    ...pagination.value,
    job: route.query.job
  }
  fetch
    .get('/jobApply', { params })
    .then(res => {
      list.value = res.list
      pagination.value.total = res.page.total
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  if (!route.query?.job) {
    message.error('参数错误')
  } else {
    initData()
  }
})
</script>

<style lang="less" scoped>
.tableCard {
  .el-table {
    min-height: 600px;
  }
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
